import React from 'react';
import 'animate.css';

export const CampsiteStatus = ({ statusItems }) => {
  return (
    <div className={'campsiteStatus'}>
      <h2>Site Status</h2>
      <ul>
        {statusItems.map((item, index) => (
          <li key={index}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};