import React, { useState } from "react";
import mapImage from "../assets/img/map.jpg"; // Correct import statement
import { Container } from "react-bootstrap";

export const Map = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <section className={`map snapScrolling ${isFullScreen ? "fullScreen" : ""}`} id="map" onClick={toggleFullScreen}>
      <Container className="mapContainer snapScrolling">
        <img src={mapImage} alt="Map" />
      </Container>
    </section>
  );
};